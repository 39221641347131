<template>
  <article>
    <loader v-if="loading" class="my-5" />
    <template v-else>
      <template v-if="totalRows">
        <orders-show-card-items-table :rows="rows" />

        <pagination
          @change:page="index($event)"
          :total="meta.total"
          :current_page="meta.current_page"
          :from="meta.from"
          :to="meta.to"
          :next_page_url="links.next"
          :prev_page_url="links.prev"
        />
      </template>

      <no-results v-else class="m-5" />
    </template>
  </article>
</template>

<script>
import ordersApi from "../api/resources/ordersApi";
import Loader from "./Loader.vue";
import NoResults from "./NoResults.vue";
import OrdersShowCardItemsTable from "./OrdersShowCardItemsTable.vue";
import Pagination from "./Pagination.vue";

export default {
  props: {
    orderId: String,
  },

  components: {
    Pagination,
    OrdersShowCardItemsTable,
    Loader,
    NoResults,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
      groupedByVendorOrder: [],
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },

    // groupedByVendorOrder() {
    //   // console.log(groupByKey(response.data, "vendor_order_id"));
    //   return this.groupByKey(this.rows, "vendor_order_id");
    // },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    groupByKey(array, key) {
      return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {
          [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
      }, {});
    },

    index(page = null) {
      this.loading = true;
      ordersApi
        .itemsIndex(this.orderId, page && { page })
        .then((response) => {
          // console.log(response.data);

          // function groupByKey(array, key) {
          //   return array.reduce((hash, obj) => {
          //     if (obj[key] === undefined) return hash;
          //     return Object.assign(hash, {
          //       [obj[key]]: (hash[obj[key]] || []).concat(obj),
          //     });
          //   }, {});
          // }

          // console.log(groupByKey(response.data, "vendor_order_id"));

          let rr = response.data;

          let grouped = rr.reduce(function (previous, a) {
            previous[a.vendor_order_id] = previous[a.vendor_order_id] || [];
            previous[a.vendor_order_id].push(a);
            return previous;
          }, []);

          // console.log(grouped);

          // this.groupedByVendorOrder = this.groupByKey(
          //   response.data,
          //   "vendor_order_id"
          // );
          this.groupedByVendorOrder = grouped;

          this.rows = response.data;
          this.meta = response.meta;
          this.links = response.links;

          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  created() {
    this.index();
  },
};
</script>

<style></style>
