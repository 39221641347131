import { ORDERS, ORDERS_SHOW, ORDERS_ITEMS } from "../endpoints";
import api from "../api";

export default {
    index(query) {
        let url = new URL(ORDERS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    show(order = null) {
        if (order === null) return;

        const url = ORDERS_SHOW.replace(":order", order);

        return api.get(url);
    },

    itemsIndex(order, query) {
        if (order === null) return;

        let url = new URL(ORDERS_ITEMS.replace(":order", order));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },
}