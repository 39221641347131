<template>
  <v-card>
    <v-card-body>
      <v-card-title>Detalii livrare</v-card-title>
      <ul>
        <li>Persoana de contact: {{ order.shipping_address?.name }}</li>
        <li>Adresa: {{ order.shipping_address?.street }}</li>
        <li>Tel: {{ order.shipping_address?.phone }}</li>
      </ul>
    </v-card-body>
  </v-card>
</template>

<script>
import VCard from "./ui/VCard.vue";
import VCardBody from "./ui/VCardBody.vue";
import VCardTitle from "./ui/VCardTitle.vue";
export default {
  components: { VCard, VCardBody, VCardTitle },
  props: {
    order: {
      type: Object,
    },
  },
};
</script>

<style>
</style>