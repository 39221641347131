<template>
  <app-layout>
    <v-container>
      <user-tabs current="comenzi" />
      <page-section>
        <page-section-header class="mt-2">
          # {{ orderId }}

          <template #meta>
            <span class="text-sm color-gray-300 font-normal custom">
              {{ order.status?.name }}
            </span>
          </template>

          <template v-slot:actions>
            <a
              v-if="order.invoice"
              :href="order.invoice.pdf"
              target="_blank"
              class="btn"
            >
              Factura
            </a>
          </template>
        </page-section-header>

        <div class="grid grid-cols-1 lg:grid-cols-3 gap-3">
          <orders-show-card-shipping :order="order" />
          <orders-show-card-billing :order="order" />
          <orders-show-card-payment :order="order" />
        </div>

        <orders-show-card-items
          class="mt-3"
          :order-id="$route.params.orderId"
        />
      </page-section>
    </v-container>
  </app-layout>
</template>

<script>
import PageSectionHeader from "../components/ui/PageSectionHeader.vue";
import AppLayout from "../layout/AppLayout.vue";
import ordersApi from "../api/resources/ordersApi";
import OrdersShowCardItems from "../components/OrdersShowCardItems.vue";
import OrdersShowCardShipping from "../components/OrdersShowCardShipping.vue";
import OrdersShowCardBilling from "../components/OrdersShowCardBilling.vue";
import OrdersShowCardPayment from "../components/OrdersShowCardPayment.vue";
import VContainer from "../components/VContainer.vue";
import UserTabs from "../components/UserTabs.vue";
import PageSection from '../components/PageSection.vue';

export default {
  components: {
    AppLayout,
    PageSectionHeader,
    OrdersShowCardItems,
    OrdersShowCardShipping,
    OrdersShowCardBilling,
    OrdersShowCardPayment,
    VContainer,
    UserTabs,
    PageSection,
  },

  props: ["orderId"],

  data() {
    return {
      order: {},
    };
  },

  created() {
    ordersApi.show(this.$route.params.orderId).then((response) => {
      this.order = response.data;
      // this.orderStatus = this.order.status.id;
    });
  },
};
</script>

<style></style>
